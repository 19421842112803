import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { cn } from '../util/styles';

import { Icon } from './Icon';

interface CopyTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
}

const MotionIcon = motion(Icon);

export const CopyText = ({ text, className, ...passthrough }: CopyTextProps) => {
  const [showCheck, setShowCheck] = useState(false);
  const [count, setCount] = useState(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.25 },
    layoutId: 'icon',
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setShowCheck(false);
    }, 1_500);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [count]);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCount((currentCount) => currentCount + 1);
        setShowCheck(true);
      }}
    >
      <span
        role="button"
        className={cn('pointer text-muted hover:bg-element-hover [&>*:last-child]:ml-2', className)}
        {...passthrough}
      >
        {text}

        <AnimatePresence mode="wait">
          {showCheck ? (
            <MotionIcon className="icon-green" name="check" {...motionProps} />
          ) : (
            <MotionIcon name="copy" size="sm" {...motionProps} />
          )}
        </AnimatePresence>
      </span>
    </CopyToClipboard>
  );
};
