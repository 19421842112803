import { useEffect } from 'react';

import { orgStore } from '../stores/OrgStore';
import * as trpc from '../util/trpc';

interface InitTrpcProps {
  children: React.ReactElement<any, any> | null;
}

export const InitTrpc = ({ children }: InitTrpcProps) => {
  useEffect(() => trpc.setActiveOrgIdGetter(() => orgStore.activeOrgId), []);

  return children;
};
