import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import { isObservable, toJS } from 'mobx';

import { type Prettify } from '../../types/util';

import { cloneDeep } from './cloneDeep';
import { kebabCaseToCamelCase, type KebabToCamelCase } from './strings';

export function typedEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

export function typedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}

/**
 * Detect if we should use `toJS` (if observable) or `cloneDeep` to clone an object.
 * @param obj Object to clone.
 */
export function cloneUnknown<T = any>(obj: T): T {
  if (isObservable(obj)) {
    return toJS(obj);
  } else {
    return cloneDeep(obj);
  }
}

export function periodSplit(field: string) {
  // Basically doing `field.split('.')` but excluding escaped periods "\\."
  // Since JS RegEx doesn't have lookbehind you have to mimic it with a reverse.
  // https://stackoverflow.com/questions/45131764/javascript-split-on-char-but-ignoring-double-escaped-chars
  const _periodSplit = field
    .split('')
    .reverse()
    .join('')
    .split(/\.(?!\\)/)
    .reverse()
    .map((x) => x.split('').reverse().join(''));

  return _periodSplit;
}

/**
 * UNSAFE to use. If working with EntryRow or EntryRowData use `extractEntryRowData` or `extractDataDotValue` instead which will ensure
 *
 * Extract value from dot.notation
 */

export function UNSAFE_extractDotValue(field: string, data?: Record<string, any>): any {
  // Need to manually "escape" the `\.` in the key names because they won't be preserved in the JS Object `data`.
  return periodSplit(field).reduce((accum, key) => (accum ? accum[key.replace(/\\\./g, '.')] : accum), data || {});
}

export function formatDotKey(field: string): string {
  const split = periodSplit(field);

  let finalKey = '';

  split.forEach((key) => {
    let indexKey = key;

    if (/\\\./g.test(key)) {
      indexKey = key.replace(/\\\./g, '.');

      finalKey = finalKey.length ? `${finalKey}["${indexKey}"]` : indexKey;
    } else {
      finalKey = `${finalKey}${finalKey.length ? '.' : ''}${indexKey}`;
    }
  });

  return finalKey;
}

export type FlatObject = Record<string, any>;
/**
 * UNSAFE to use. If working with EntryRow or EntryRowData use `flattenObject` instead which will ensure
 * you're passing one of those types.
 *
 * Transform a nested object into a map of dot.notation keys to values
 * Also escape key names that contain a period. Example...
 * { "owner.name": "Bill" }
 * ...becomes...
 * owner\.name
 */
export function UNSAFE_flattenObject(obj: any, path: any[] = []): FlatObject {
  return Array.isArray(obj) || !isObject(obj)
    ? { [path.join('.')]: obj }
    : Object.keys(obj).reduce(
        (accum, key) => merge(accum, UNSAFE_flattenObject(obj[key], [...path, key.replace(/\./g, '\\.')])),
        {}
      );
}

export function isObject(value: unknown): boolean {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function splitKey(key: string) {
  return key.split(/(?<!\\)\./).map((k) => k.replace(/\\\./g, '.'));
}

export function unflattenObject(flatObj: Record<string, unknown>): any {
  const keys = Object.keys(flatObj).map((key) => splitKey(key));

  // sort so the longest keys are processed first
  keys.sort((a, b) => b.length - a.length);

  const nestedNonEmpty = keys.reduce(
    (acc, parts) => {
      const key = parts.join('');
      const val = flatObj[key];

      if (val === undefined && val === null) {
        return acc;
      }

      let current = acc;

      parts.forEach((part, i) => {
        if (i === parts.length - 1) {
          current[part] = flatObj[key];
        } else {
          current = current[part] = current[part] || {};
        }
      });

      return acc;
    },
    {} as Record<string, any>
  );

  return Object.keys(flatObj).reduce((result, key) => {
    const parts = splitKey(key);
    let current = result;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (i === parts.length - 1) {
        current[part] = flatObj[key] ?? null;
      } else {
        current = current[part] = current[part] || {};
      }

      const isObjOrEmpty = isObject(current) || current === null || current === undefined;

      if (!isObjOrEmpty) {
        break;
      }
    }

    return result;
  }, nestedNonEmpty);
}

type LeafNode<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T as keyof any]: LeafNode<T[K]>;
    }
  : T;

/**
 * Transform a nested object into a flat object without change to the leaf nodes.
 * {"a": {"b": 2}, "c": 3} becomes {"b": 2, "c": 3}
 */
export function flattenObjectWithoutPreservingParents<T extends Record<string, unknown>>(obj: T): LeafNode<T> {
  const result = {} as LeafNode<T>;

  function flatten(source: Record<string, unknown>, target: Record<string, unknown>) {
    for (const key in source) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        flatten(source[key] as Record<string, unknown>, target);
      } else {
        target[key] = source[key];
      }
    }
  }

  flatten(obj, result);

  return result;
}

/**
 * Takes an object or array and recursively removes all keys with a value of `null`.
 *
 * This is a lot of extra type work for not a lot of extra type safety
 * All these generics do is remove `null`s from the return type
 * If this is scary and you want it gone, revert the commit where these types and this comment were added
 */
type RemoveTupleNull<T extends unknown[]> = T extends [infer first, ...infer rest]
  ? rest extends Record<string, never>
    ? never
    : first extends null
    ? RemoveTupleNull<rest>
    : [RemoveNull<first>, ...RemoveTupleNull<rest>]
  : [];
type RemoveArrayNull<T extends unknown[]> = number extends T['length'] ? RemoveNull<T[number]>[] : RemoveTupleNull<T>;
type RemoveNull<T> = T extends unknown[]
  ? RemoveArrayNull<T>
  : T extends Record<string, unknown>
  ? Prettify<{
      [k in keyof T as T[k] extends null ? never : k]: T[k] extends object ? RemoveNull<T[k]> : T[k];
    }>
  : Exclude<T, null>;

export function deepRemoveNullValues<T extends unknown[] | Record<string, unknown>>(input: T): RemoveNull<T> {
  if (Array.isArray(input)) {
    return [...input]
      .filter((item) => !isNil(item))
      .map((item) => (isObject(item) ? deepRemoveNullValues(item as T) : item)) as never;
  }

  const inputClone = { ...input };
  // eslint-disable-next-line guard-for-in
  for (const key in inputClone) {
    const value = inputClone[key];
    if (value === null) {
      delete inputClone[key];
    } else if (isObjectLike(value)) {
      inputClone[key] = deepRemoveNullValues(value as unknown as T) as never;
      if (isPlainObject(value) && isEmpty(inputClone[key])) {
        delete inputClone[key];
      }
    }
  }

  return inputClone as never;
}

export function isValueEmptyAfterRemovingNulls(value: any): boolean {
  if (isNil(value) || value === '') {
    return true;
  }

  const isArrayOrObject = Array.isArray(value) || isObject(value);

  if (isArrayOrObject) {
    const removedNulls = deepRemoveNullValues(value);

    return isEmpty(removedNulls) || isNil(removedNulls) || removedNulls === '';
  }

  return isNil(value) || value === '';
}

export function prepareFaroObject(obj: Record<string, any> | undefined): Record<string, string> | undefined {
  // not possible at the type level, but better to be safe at runtime
  if (typeof obj !== 'object' || !obj) {
    return undefined;
  }

  return typedEntries(obj).reduce(
    (acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          try {
            const stringified = JSON.stringify(value);
            if (stringified === '' || stringified === '{}') {
              throw new Error("Can't stringify this");
            }
            acc[key] = stringified;
          } catch (e) {
            acc[key] = `Failed to stringify` as any;
          }
          acc[key] = JSON.stringify(value) as any;
        } else {
          acc[key] = value.toString();
        }
      }

      return acc;
    },
    {} as Record<string, string>
  );
}

export type ConvertKeysToCamelCase<T extends Record<string, any>> = {
  [K in keyof T as KebabToCamelCase<K & string>]: T[K];
};

// 🚨 this is not recursive
export function convertKeysToCamelCase<T extends Record<string, any>>(obj: T): ConvertKeysToCamelCase<T> {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = kebabCaseToCamelCase(key);
      newObj[camelCaseKey] = obj[key];
    }
  }

  return newObj as ConvertKeysToCamelCase<T>;
}

export function updateKeyValueRecursively<T extends object>(obj: T, { key, value }: { key: string; value: any }) {
  // don't try to update key if obj is an array
  const updatedObj = Array.isArray(obj) ? obj : cloneUnknown({ ...obj, [key]: value });

  // Recursively update key/value in nested objects
  if (updatedObj && typeof updatedObj === 'object') {
    typedKeys(updatedObj).forEach((k) => {
      if (typeof updatedObj[k] === 'object' && updatedObj[k] !== null) {
        const nestedObj = updatedObj[k];
        updatedObj[k] = updateKeyValueRecursively(nestedObj, { key: key, value: value });
      }
    });
  }

  return updatedObj;
}

export const removeUndefinedValues = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
};
