import { type DialogContentProps } from '@radix-ui/react-dialog';
import React, { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import { ANIMATION_DURATION_LOW } from '../util/animations';
import { focusSomething } from '../util/focusSomething';
import { cn } from '../util/styles';

import { Button, type ButtonProps, type ButtonType } from './Button';
import { ModalBase_DEPRECATED, type ModalBaseProps } from './ModalBase_DEPRECATED';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from './primitives/Dialog';

import styles from './Modal.module.scss';

export interface ModalProps extends ModalBaseProps {
  wrapClassName?: string;
  middleAlign?: boolean;
  noCancel?: boolean;
  noOk?: boolean;
  noBodyPadding?: boolean;
  focusSelectors?: string[];
}

const onKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
  event.stopPropagation();
};

const Modal = Dialog;
const ModalContent = ({
  className,
  noBodyPadding,
  ...rest
}: DialogContentProps & {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  noBodyPadding?: boolean;
  hideClose?: boolean;
}) => <DialogContent className={cn(styles.modal, noBodyPadding && '!p-0', className)} {...rest} />;

/**
 * To be used within a modal stack.
 *
 * ```
 * <Modal>
 *  <ModalContent noBodyPadding>
 *    <ModalHeader>Warning</ModalHeader>
 *    <ModalContentBody>
 *      <p>Are you sure you want to delete this?</p>
 *   </ModalContentBody>
 *   <ModalFooter>
 *      <ModalCancelButton>Cancel</ModalCancelButton>
 *      <ModalOkButton>OK</ModalOkButton>
 *   </ModalFooter>
 * </Modal>
 * ```
 */
export const ModalContentBody = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <div className={cn('p-4', className)}>{children}</div>
);

const ModalFooter = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <DialogFooter className={cn('flex w-full justify-between gap-6 rounded-b border-t-base bg-muted p-4', className)}>
    {children}
  </DialogFooter>
);
const ModalCancelButton = ({ children, ...props }: ButtonProps) => (
  <DialogClose asChild>
    <Button type="axi-secondary" {...props} className={cn('min-w-[160px]', props.className)}>
      {children || 'Cancel'}
    </Button>
  </DialogClose>
);
const ModalOkButton = ({ children, ...props }: ButtonProps) => (
  <Button type="axi-primary" {...props} className={cn('min-w-[160px]', props.className)}>
    {children || 'OK'}
  </Button>
);

const ModalHeader = ({ children, className }: { className?: string; children?: React.ReactNode }) => (
  <DialogHeader className={cn('w-full p-4', className)}>
    <h1 className="text-center text-xl">{children}</h1>
  </DialogHeader>
);

const ModalTrigger = DialogTrigger;

export { Modal, ModalContent, ModalFooter, ModalHeader, ModalTrigger, ModalCancelButton, ModalOkButton };

/**
 * The goal of this component is to be API compatible with Modal_DEPRECATED
 * without using rc-dialog.
 *
 * That's probably not entirely true yet, I only worked on it until it worked
 * in the place where I needed it.
 *
 * For new usage, I would strongly suggest to make a new component with
 * fewer props.
 */
export const ModalCompat = (
  props: ModalProps & { open: boolean; onOpenChange: (open: boolean) => void; trigger: React.ReactNode }
) => {
  const [focused, setFocused] = useState(false);

  const {
    cancelButtonProps,
    cancelText,
    children,
    focusSelectors,
    middleAlign,
    noBodyPadding,
    noCancel,
    noOk,
    onCancel,
    onOk,
    onOpenChange,
    okButtonProps,
    okLoading,
    okType,
    okText,
    open,
    title,
    trigger,
    visible,
    width,
    wrapClassName,
    ...passthrough
  } = props;

  useEffect(() => {
    if (visible && !focused) {
      flushSync(() => {
        setFocused(true);
      });

      setTimeout(() => {
        setTimeout(() => {
          // try focus first element in the Modal.
          // @TODO: Replace this with a ref to the first focusable element in the Modal.
          const menuDom = document.querySelector<HTMLDivElement>('.axiom-modal-root');
          if (menuDom) {
            focusSomething(menuDom, focusSelectors || ['.btn']);
          }
        }, ANIMATION_DURATION_LOW);
      });
    } else if (!visible) {
      setFocused(false);
    }
  }, [visible, focused, focusSelectors]);

  let cancelType: ButtonType = 'axi-secondary';
  const actualOkType: ButtonType = okType || 'axi-primary';

  if (noOk) {
    // Give Cancel button's variant setting that of Ok since we want to promote it to the primary action.
    cancelType = actualOkType;
  }

  return (
    <Modal open={open} onOpenChange={onOpenChange} {...passthrough}>
      <ModalTrigger asChild>{trigger}</ModalTrigger>
      <ModalContent
        className={cn(wrapClassName, {
          '!p-0': noBodyPadding,
        })}
        style={{ width: `${width}px` }}
      >
        <ModalHeader>{title}</ModalHeader>

        {children}

        {!(noCancel && noOk) ? (
          <DialogFooter>
            {!noCancel ? (
              <Button
                onClick={() => onOpenChange(false)}
                type={cancelType || 'axi-secondary'}
                {...cancelButtonProps}
                className={cn(cancelButtonProps?.className)}
                onKeyPress={onKeyPress}
              >
                {cancelText || 'Cancel'}
              </Button>
            ) : null}
            {!noOk ? (
              <Button
                type={actualOkType}
                loading={okLoading}
                onClick={onOk}
                {...okButtonProps}
                className={cn(okButtonProps?.className)}
                onKeyPress={onKeyPress}
              >
                {okText || 'OK'}
              </Button>
            ) : null}
          </DialogFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

/**
 * @deprecated Depends on rc-* component
 */
export class Modal_DEPRECATED extends React.Component<ModalProps> {
  private focused = false;

  constructor(props: ModalProps) {
    super(props);
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.visible && !this.focused) {
      // only focus once, in case componentDidUpdate is called multiple times.
      this.focused = true;

      // give the modal some time to animate in, then focus the first focusable element.
      setTimeout(() => {
        // try focus first element in the Modal.
        // @TODO: Replace this with a ref to the first focusable element in the Modal.
        const menuDom = document.querySelector<HTMLDivElement>('.axiom-modal-root');
        if (menuDom) {
          focusSomething(menuDom, this.props.focusSelectors || ['.btn']);
        }
      }, ANIMATION_DURATION_LOW);
    } else if (!this.props.visible) {
      this.focused = false;
    }
  }

  render() {
    const {
      cancelText,
      children,
      okLoading,
      middleAlign,
      noCancel,
      noBodyPadding,
      noOk,
      okText,
      okType,
      wrapClassName,
      ...passthrough
    } = this.props;

    let cancelType: ButtonType = 'axi-secondary';
    const actualOkType: ButtonType = okType || 'axi-primary';

    if (noOk) {
      // Give Cancel button's variant setting that of Ok since we want to promote it to the primary action.
      cancelType = actualOkType;
    }

    // the vertical-center-modal is for antd.
    return (
      <ModalBase_DEPRECATED
        wrapClassName={cn('vertical-center-modal', styles.modal, wrapClassName, {
          [styles.noBodyPadding]: noBodyPadding,
        })}
        closable={false}
        width={460}
        centered
        // have to send through as a style? can't add a class to the mask
        maskStyle={{
          backgroundColor: 'rgba(0.0, 0.0, 0.0, 0.0)',
          backgroundImage: 'radial-gradient(rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.5))',
        }}
        footer={
          !(noCancel && noOk) ? (
            <div>
              {!noCancel ? (
                <Button
                  onClick={this.onCancel}
                  type={cancelType || 'axi-secondary'}
                  {...this.props.cancelButtonProps}
                  className={cn(this.props.cancelButtonProps?.className)}
                  onKeyPress={this.onKeyPress}
                >
                  {cancelText || 'Cancel'}
                </Button>
              ) : null}
              {!noOk ? (
                <Button
                  type={actualOkType || 'axi-primary'}
                  loading={okLoading}
                  onClick={this.onOk}
                  {...this.props.okButtonProps}
                  className={cn(this.props.okButtonProps?.className)}
                  onKeyPress={this.onKeyPress}
                >
                  {okText || 'OK'}
                </Button>
              ) : null}
            </div>
          ) : null
        }
        {...passthrough}
      >
        {children}
      </ModalBase_DEPRECATED>
    );
  }

  onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel(event);
    }
  };

  onOk = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onOk } = this.props;

    if (onOk) {
      onOk(event);
    }
  };

  onKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
  };
}
