import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Clickable } from '../../components/Clickable';
import { Icon } from '../../components/Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/primitives/Popover';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../../components/primitives/Select_deprecated';
import { SHOW_TANSTACK_DEVTOOLS } from '../../contexts/TanstackQueryTrpc';
import { UserPreferencesStoreContext } from '../../stores/UserPreferencesStore';
import { useRequiredContext } from '../hooks/util/useRequiredContext';
import { Logger } from '../logger';
import { getQueryFormat, QueryFormat, setQueryFormatOverride } from '../queryFormat';
import { safeLocalStorage } from '../safeLocalStorage';
import { cn } from '../styles';

import { type FeatureFlagId } from './config';
import { featureFlagStore } from './FeatureFlagStore';
import { useFeatureFlags } from './useFeatureFlags';

const HIDE_SELECTOR_KEY = 'featureFlagSelectorHidden';

export const FeatureFlagsSelector = observer(function FeatureFlagSelector() {
  // this block of code is just to get make sure the selector is up-to-date once ld loads.
  const {
    progressiveQueries: progressiveQueriesEnabledLD,
    tabularOnly,
    feUpdatedDatasetsPageY25W09: updatedDatasetsPageLD,
  } = useFeatureFlags();
  const queryFormat = getQueryFormat();
  console.info('using format:', queryFormat ?? tabularOnly);
  const userPreferencesStore = useRequiredContext(UserPreferencesStoreContext);

  const isHiddenItem = safeLocalStorage.getItem(HIDE_SELECTOR_KEY) === 'true';

  const [isHidden, setIsHidden] = useState(isHiddenItem);

  if (!featureFlagStore.hasAtLeastOneFlag) {
    return null;
  }

  const onHideClick = () => {
    safeLocalStorage.setItem(HIDE_SELECTOR_KEY, 'true');
    setIsHidden(true);
  };

  (window as any).enableFeatureFlagSelector = () => {
    safeLocalStorage.removeItem(HIDE_SELECTOR_KEY);
    setIsHidden(false);
  };

  if (isHidden) {
    Logger.info('Feature flag selector is hidden. Run `enableFeatureFlagSelector()` to show it.');

    return null;
  }

  const { flagsThatCanBeToggled: availableFlags } = featureFlagStore;

  return (
    <div
      className={cn('fixed bottom-3 left-3 z-[9999]', {
        'left-[72px]': SHOW_TANSTACK_DEVTOOLS,
      })}
    >
      <Popover>
        <PopoverTrigger aria-label="Show feature flags">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border bg-[--gray-03] hover:bg-[--gray-05]">
            <Icon name="toggle-on" variant="fal" size="2x" color="ok" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="mr-3">
          <div className="p-4">
            <ul className="flex flex-col gap-4">
              <div className="flex w-full justify-center gap-4">
                <p className="font-bold">Feature flags</p>
                <Clickable onClick={onHideClick}>
                  <Icon name="eye" aria-label="hide feature flag selector" />
                </Clickable>
              </div>
              <div className="flex flex-col gap-2">
                {availableFlags.map((flag) => {
                  const hasFlag = featureFlagStore.hasFeatureFlag(flag.id as FeatureFlagId);

                  return (
                    <li className="flex w-full justify-between gap-6" key={flag.id}>
                      <p>{flag.name}</p>
                      <Checkbox
                        className="!p-0"
                        checked={hasFlag}
                        onChange={(value) => featureFlagStore.setFeatureFlag(flag.id as FeatureFlagId, !hasFlag)}
                      />
                    </li>
                  );
                })}
                {progressiveQueriesEnabledLD ? (
                  <li className="flex w-full justify-between gap-6">
                    <p>progressive-queries</p>
                    <Checkbox
                      className="!p-0"
                      checked={userPreferencesStore.queryMode === 'progressive'}
                      onChange={(value) => {
                        if (userPreferencesStore.queryMode === 'progressive') {
                          userPreferencesStore.updateQueryMode('batched');
                        } else {
                          userPreferencesStore.updateQueryMode('progressive');
                        }
                      }}
                    />
                  </li>
                ) : null}

                {updatedDatasetsPageLD ? (
                  <li className="flex w-full justify-between gap-6">
                    <p>new-datasets-page</p>
                    <Checkbox
                      className="!p-0"
                      checked={userPreferencesStore.updatedDatasetsPageY25W09}
                      onChange={(value) => {
                        userPreferencesStore.updateUpdatedDatasetsPageY25W09(
                          !userPreferencesStore.updatedDatasetsPageY25W09
                        );
                      }}
                    />
                  </li>
                ) : null}

                <li className="flex w-full items-center justify-between gap-6">
                  <p>format</p>
                  <Select
                    // type="axi-default"
                    onValueChange={(value) => {
                      setQueryFormatOverride(value as any);
                    }}
                    defaultValue={queryFormat}
                  >
                    <SelectTrigger
                      loading={false}
                      // onBlur={onBlur}
                      hasError={false}
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(QueryFormat).map((format) => (
                        <SelectItem key={format} value={format}>
                          {format}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </li>
              </div>
              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh page
                </Button>
              </div>
            </ul>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
});
