import { cva } from 'class-variance-authority';
import React from 'react';
import { type FieldError } from 'react-hook-form';

import { cn } from '../../util/styles';
import { type RadixClassNameTypeHelper } from '../primitives/temporaryRadixTypeHelper';

const helperVariants = cva('text-subtle mb-0 text-sm leading-6 [&_p:last-child]:mb-0', {
  variants: {
    variant: {
      default: '',
      terminal: 'type-mono/md/regular pl-2 !leading-4 text-muted',
    },
    state: {
      default: '',
      error: 'text-red',
    },
  },
  defaultVariants: {
    variant: 'default',
    state: 'default',
  },
});

export const FormItemHelper = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    RadixClassNameTypeHelper & {
      helperText: React.ReactNode;
      hasError?: boolean; // manually add error for situations where we can't rely on react-hook-form
      error?: FieldError | string;
      formMessageId?: string;
      helperVariant?: 'default' | 'terminal';
    }
>((props, ref) => {
  const { className, hasError, helperText, formMessageId, error, helperVariant, ...passthrough } = props;

  const text = error ? (typeof error === 'string' ? error : String(error?.message)) : helperText ?? '';
  const isTerminal = helperVariant === 'terminal';

  const c = helperVariants({
    variant: helperVariant,
    state: error || hasError ? 'error' : 'default',
    className: className,
  });

  return (
    <div
      className={cn(
        'self-center overflow-hidden align-middle transition-all duration-150 animate-in fade-in',
        text ? 'h-auto' : 'h-0'
      )}
    >
      <FormItemWrapper variant={helperVariant}>
        {isTerminal && <p className="type-mono/md/regular text-placeholder">└ </p>}
        <p ref={ref} id={formMessageId} data-testid="formItemMessage" className={c} {...passthrough}>
          {text ?? helperText ?? ''}
        </p>
      </FormItemWrapper>
    </div>
  );
});

FormItemHelper.displayName = 'FormItemHelper';

const FormItemWrapper = ({ children, variant }: { children: React.ReactNode; variant?: 'terminal' | 'default' }) => {
  if (variant === 'terminal') {
    return <div className="flex items-start pl-2 pt-2">{children}</div>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
