import React from 'react';

import { cn } from '../util/styles';

import { Spin } from './Spin';

export const Loading = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-grow flex-col items-center justify-center gap-4',
        'duration-200 animate-in fade-in'
      )}
    >
      <Spin />
    </div>
  );
};
