/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { type CreateTRPCClientOptions, createTRPCClient, httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';

import { type RootTRPCRouter } from '../../../../frontend-server/src/trpc/root-router';

const getBaseUrl = () => {
  // We're not doing any server side stuff for now, but this will become relevant if we start
  // using Next.js or anything else that runs client code on the server.

  // if (typeof window === 'undefined') {
  //   // TODO: figure out prod SSR (might need to be an environment variable?)
  //
  //   // SSR should use vercel url
  //   if (process.env.VERCEL_URL) {
  //     return `https://${process.env.VERCEL_URL}`;
  //   }
  //   // dev SSR should use localhost
  //   return `http://localhost:${process.env.PORT ?? 3000}`;
  // }

  // browser should use relative url
  return '';
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const trpc = createTRPCReact<RootTRPCRouter>();

let getActiveOrgId: () => string | undefined = () => undefined;

export function setActiveOrgIdGetter(fn: () => string | undefined) {
  getActiveOrgId = fn;
}

const trpcOpts: CreateTRPCClientOptions<RootTRPCRouter> = {
  /**
   * Links used to determine request flow from client to server.
   *
   * @see https://trpc.io/docs/links
   */
  links: [
    loggerLink({
      enabled: (opts) =>
        process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
    }),
    httpBatchLink({
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      url: `${getBaseUrl()}/frapi/trpc`,
      headers: function () {
        return {
          /**
           * Required to make it past CSRF protection on the backend
           */
          'x-axiom-check': 'good',
          'x-axiom-org-id': getActiveOrgId() ?? '',
        };
      },
    }),
  ],
};

export const createTRPCReactClient = () => {
  return trpc.createClient(trpcOpts);
};

/**
 * Use this when you don't want to use the React Query hooks.
 * For example: fetching imperatively in a MobX store.
 */
export const imperativeTRPCClient = createTRPCClient<RootTRPCRouter>(trpcOpts);

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = TRPCRouterInputs['example']['hello']
 */
export type TRPCRouterInputs = inferRouterInputs<RootTRPCRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = TRPCRouterOutputs['example']['hello']
 */
export type TRPCRouterOutputs = inferRouterOutputs<RootTRPCRouter>;
